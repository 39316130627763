import { Component, OnInit } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  isRehearsalCollapsed = false;
  isWeddingCollapsed = false;
  invitedRehearsal = false;
  isCruiseCollapsed = false;
  constructor(private cookieService: CookieService) { }

  ngOnInit() {
    if (this.cookieService.check('invitedRehearsal')) {
      this.invitedRehearsal = this.cookieService.get('invitedRehearsal').toLowerCase().startsWith('t');
    }
  }

}
