import {Component, OnInit} from '@angular/core';
import {RsvpService} from './services/rsvp.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isNavCollapsed = true;
  hideContainer = true;
  rsvpMade = false;
  constructor(private rsvpService: RsvpService, private cookieService: CookieService) {

  }

  ngOnInit() {
    this.hideContainer = window.location.href.toLowerCase().endsWith('wedding');
    if (this.cookieService.check('reservationId') && this.cookieService.check('reservationPrimaryId')
      && this.cookieService.check('reservationPartyName')) {
      this.rsvpMade = true;
    }
    if (!this.cookieService.check('seenAlert')) {
      window.alert('Our plans have changed. For more information on the updates. Please see the tab called \'Coronavirus\'')
      this.cookieService.set('seenAlert', 'true');
    }

    this.rsvpService.rsvpChange.subscribe(
      () => (this.rsvpMade = true)
      );
  }

}
