import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AppComponent } from './app.component';
import { WeddingComponent } from './wedding/wedding.component';
import { InfoComponent } from './info/info.component';
import { EventsComponent } from './events/events.component';
import { RsvpComponent } from './rsvp/rsvp.component';
import { PhotosComponent } from './photos/photos.component';
import {UIRouterModule} from '@uirouter/angular';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { RsvpService } from './services/rsvp.service';
import { ReservationComponent } from './reservation/reservation.component';
import { FormsModule } from '@angular/forms';
import {Angular2ImageGalleryModule} from 'angular2-image-gallery';
import {OcticonDirective} from './common/octicon/octicon.directive';
import {CookieService} from 'ngx-cookie-service';
import {LoadingComponent} from './common/loading/loading.component';
import {LoadingService} from './services/loading.service';
import {LoadingInterceptor} from './common/loading/loading.interceptor';
import {SaveInterceptor} from './common/loading/save.interceptor';
import {CoronavirusComponent} from './coronavirus/coronavirus.component';


@NgModule({
  declarations: [
    AppComponent,
    WeddingComponent,
    LoadingComponent,
    InfoComponent,
    EventsComponent,
    RsvpComponent,
    CoronavirusComponent,
    PhotosComponent,
    ReservationComponent,
    OcticonDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    Angular2ImageGalleryModule,
    UIRouterModule.forRoot({
      states: [
        {name: 'wedding', url: '/wedding', component: WeddingComponent},
        {name: 'coronavirus', url: '/coronavirus', component: CoronavirusComponent},
        {name: 'info', url: '/info', component: InfoComponent},
        {name: 'events', url: '/events', component: EventsComponent},
        {name: 'rsvp', url: '/rsvp', component: RsvpComponent},
        {name: 'photos', url: '/photos', component: PhotosComponent}
      ],
      otherwise: 'wedding'
    }),
    NgbModule
  ],
  providers: [RsvpService, CookieService, LoadingService,
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SaveInterceptor, multi: true  } ],
  bootstrap: [AppComponent]
})
export class AppModule { }
