import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {LoadingService} from '../../services/loading.service';

@Injectable()
export class SaveInterceptor implements HttpInterceptor {
  private successCount = 0;
  private errorCount = 0;

  constructor(private loadingService: LoadingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (request.method !== 'POST') {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      tap(res => {
        if (res instanceof HttpResponse) {
          if (res.ok) {
            this.increaseSuccess();
          } else {
            this.increaseError();
          }
        }
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  private increaseSuccess() {
    this.successCount++;
    this.loadingService.setSuccess(true);
    setTimeout(() => {
      this.successCount--;
      this.loadingService.setSuccess(this.successCount > 0);
    }, 1500);
  }

  private increaseError() {
    this.errorCount++;
    this.loadingService.setError(true);
    setTimeout(() => {
      this.errorCount--;
      this.loadingService.setError(this.errorCount > 0);
    }, 1500);
  }
}
