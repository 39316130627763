export class Attendee {
    name: string;
    id?: string;
    address1?: string;
    address2?: string;
    state?: string;
    city?: string;
    zip?: string;
    email?: string;
    notes?: string;
    glutenFree?: boolean;
    vegetarian?: boolean;
    vegan?: boolean;
    allergies?: boolean;
    allergyText?: string;
    attendingRehearsal?: boolean;
    attendingWedding?: boolean;
    isPlusOne?: boolean;
    expanded?: boolean;
    invitedRehearsal?: boolean;
    changed?: boolean;
    attendingCruise?: boolean;
    notAttendingRehearsal?: boolean;
    notAttendingWedding?: boolean;
    notAttendingCruise?: boolean;
    teeterPickup?: boolean;
    hamptonPickup?: boolean;
    residencePickup?: boolean;
}
