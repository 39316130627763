import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-wedding',
  templateUrl: './wedding.component.html',
  styleUrls: ['./wedding.component.css']
})
export class WeddingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
