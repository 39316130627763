import {Injectable} from '@angular/core';
@Injectable()
export class LoadingService {
  loading = false;

  success = false;

  error = false;

  setLoading(l: boolean) {
    this.loading = l;
  }

  setSuccess(s: boolean) {
    this.success = s;
  }

  setError(e: boolean) {
    this.error = e;
  }

}
