import { Component, OnInit, Input } from '@angular/core';
import { Attendee } from '../model/attendee';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {

  @Input() attendee: Attendee;

  uuid = Math.floor(Math.random() * 1000);

  constructor() { }

  ngOnInit() {
  }


}
