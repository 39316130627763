import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Rsvp } from '../model/rsvp';
import { Reservation } from '../model/reservation';
import {Observable, Observer} from 'rxjs';
@Injectable()
export class RsvpService {
    baseUrl = 'https://us-central1-gibel-wedding.cloudfunctions.net/';
    rsvpChange: Observable<any>;
    private _observer: Observer<any>;
    constructor(private http: HttpClient) {
      this.rsvpChange = new Observable((observer: Observer<any>) => {
        this._observer = observer;
      });
    }
  private static buildParamString(data: any): string {
    const str = [];
    for (const p in data) {
      if (data.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]));
      }
    }
    return str.join('&');
  }

    addReservation(rsvp: Rsvp): Promise<any> {
      return this.http.post(this.baseUrl + 'addReservation', rsvp).toPromise();
    }

    deleteReservation(rsvp: Rsvp): Promise<any> {
      return this.http.post<Rsvp>(this.baseUrl + 'deleteReservation', rsvp).toPromise();
    }

    updateReservation(rsvp: Rsvp): Promise<any> {
      this._observer.next(rsvp);
      return this.http.post<Rsvp>(this.baseUrl + 'updateReservation', rsvp).toPromise();
    }

    getReservation(id: string, primary: string): Promise<Rsvp> {
      return this.http.get<Rsvp>(this.baseUrl + 'getReservation?' + RsvpService.buildParamString({id: id, primary: primary})).toPromise();
    }

    findReservation(terms: string): Promise<Reservation[]> {
      return this.http.get<Reservation[]>(this.baseUrl + 'findReservation?' + RsvpService.buildParamString({term: terms})).toPromise();
    }
}
