import { Component, OnInit } from '@angular/core';
import { RsvpService } from '../services/rsvp.service';
import { Rsvp } from '../model/rsvp';
import { Reservation } from '../model/reservation';
import {Attendee} from '../model/attendee';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.css']
})
export class RsvpComponent implements OnInit {

  rsvp: Rsvp;
  attendeesToDelete: Attendee[] = [];
  attendeesToUpdate: Attendee[] = [];
  attendeesToAdd: Attendee[] = [];

  searchResults: Reservation[];

  searchText: string;

  constructor(private rsvpService: RsvpService, private cookieService: CookieService) { }

  ngOnInit() {
    if (this.cookieService.check('reservationId') && this.cookieService.check('reservationPrimaryId')
      && this.cookieService.check('reservationPartyName')) {
      const reservation: Reservation = {
        partyName: this.cookieService.get('reservationPartyName'),
        partyId: this.cookieService.get('reservationId'),
        primaryId: this.cookieService.get('reservationPrimaryId'),
        primaryName: this.cookieService.get('reservationPrimaryName'),
      };
      this.getReservation(reservation);
    }
  }

  getReservation(reservation: Reservation) {
    this.rsvpService.getReservation(reservation.partyId, reservation.primaryId).then((results) => {
      this.rsvp = results;
      this.rsvp.id = reservation.partyId;
      if(this.rsvp.primaryAttendee.invitedRehearsal) {
        this.cookieService.set('invitedRehearsal', 'true');
      }
    }).catch((error) => {
      console.error('Error contacting service');
      console.error(error);
    });
  }

  getRsvp(reservation: Reservation) {
    this.cookieService.set('reservationId', reservation.partyId);
    this.cookieService.set('reservationPrimaryId', reservation.primaryId);
    this.cookieService.set('reservationPrimaryName', reservation.primaryName);
    this.cookieService.set('reservationPartyName', reservation.partyName);
    this.getReservation(reservation);
  }

  updateRsvp() {
    this.attendeesToUpdate.push(this.rsvp.primaryAttendee);
    this.rsvp.secondaryAttendees.forEach((attendee) => {
      attendee.changed = true;
      if (attendee.id) {
        this.attendeesToUpdate.push(attendee);
      } else {
        this.attendeesToAdd.push(attendee);
      }
    });
    const rsvpToUpdate: Rsvp = {
      id: this.rsvp.id,
      attendees: this.attendeesToUpdate
    };
    const rsvpToAdd: Rsvp = {
      id: this.rsvp.id,
      attendees: this.attendeesToAdd
    };
    const rsvpToRemove: Rsvp = {
      id: this.rsvp.id,
      attendees: this.attendeesToDelete
    };
    const promises = [];
    if (rsvpToAdd.attendees.length > 0) {
      promises.push(this.rsvpService.addReservation(rsvpToAdd));
    }
    if (rsvpToUpdate.attendees.length > 0) {
      promises.push(this.rsvpService.updateReservation(rsvpToUpdate));
    }
    if (rsvpToRemove.attendees.length > 0) {
      promises.push(this.rsvpService.deleteReservation(rsvpToRemove));
    }
    Promise.all([promises])
      .then((statuses) => {
        console.log('Successful update');
    }).catch((error) => {
      console.error('Error update', error);
    });
  }

  clearReservation(): void {
    this.rsvp = null;
    this.attendeesToDelete = [];
    this.attendeesToAdd = [];
    this.attendeesToUpdate = [];
    this.cookieService.delete('reservationId');
    this.cookieService.delete('reservationPrimaryId');
    this.cookieService.delete('reservationPrimaryName');
    this.cookieService.delete('reservationPartyName');
    this.cookieService.delete('invitedRehearsal');
  }

  findReservation(name: string): void {
    this.rsvpService.findReservation(name).then((results) => {
      this.searchResults = results;
    }).catch((error) => {
      console.error('Error contacting service');
      console.error(error);
    });
  }

  addAttendee(): void {
    this.rsvp.secondaryAttendees.push(
      {
        name: '(New Attendee)',
        isPlusOne: true,
        expanded: true
      }

      );
  }

  removeAttendee(index: number): void {
    this.attendeesToDelete.push(this.rsvp.secondaryAttendees[index]);
    this.rsvp.secondaryAttendees.splice(index, 1);
  }

}
